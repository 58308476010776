import { createGlobalStyle, css } from "styled-components";

export const siteWidth = 900;

export const blue = "#326a9a";
export const purple = "#693474";

// Break points
// @media (min-width: ${bp.medium}px) { ... }
export const bp = {
  medium: 600,
  large: siteWidth,
};

/**
 * Fonts
 */

// for some reason, @import in the createGlobalStyle doesn't work
import "./fonts.css";

export const serif = css`
 Merriweather, serif
 `;

export const sanSerif = css`
 Open Sans, san-serif
 `;

/**
 * GlobalStyles
 */

// @import url("https://fonts.googleapis.com/css?family=Alegreya+SC|Alegreya:400,700");
// this import now happens above

export const GlobalStyles = createGlobalStyle`

    body {
      margin: 0;
      padding: 0;

      font-size: 16px;
      font-family: ${sanSerif};
      line-height: 1.7;
    }

  `;
