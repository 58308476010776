import styled from "styled-components";
import { serif } from "../../common/styles.js";

export const Images = styled.div``;

export const Container = styled.div`
  padding: 15px 10px 30px;

  & + & {
    border-top: 3px solid #6d91b0;
    margin-top: 50px;
  }

  @media (min-width: 600px) {
    ${Images} {
      float: ${(props) => (props.isOdd ? "right" : "left")};
      ${(props) => (props.isOdd ? "margin-left" : "margin-right")}: 10px;
    }

    &::after {
      display: table;
      clear: both;
      content: "";
    }
  }

  /* clear for floating image */
`;

export const Header = styled.div`
  margin-bottom: 12px;

  a {
    display: block;
    color: #162e44;

    &:hover {
      text-decoration: none;
    }

    svg {
      display: inline;
      height: 16px;
      width: 16px;
      margin-left: 4px;
      vertical-align: middle;
    }
  }

  h2 {
    display: inline-block;
    margin: 0 10px 0 0;

    color: #22486a;
    font-family: ${serif};
    font-size: 30px;
    font-weight: normal;
    line-height: 36px;
  }
`;

export const Date = styled.div`
  display: inline-block;
  color: #22486a;
  font-family: ${serif};
  font-size: 18px;
`;

export const Body = styled.div``;

export const Technologies = styled.div`
  margin: 8px 0;

  code {
    color: #942a15;
    margin-left: 10px;
  }
`;
