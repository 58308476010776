import React from "react";
import { node } from "prop-types";
import { GlobalStyles } from "../../common/styles.js";

const propTypes = {
  children: node,
};

const Page = (props) => {
  const { children } = props;

  return (
    <React.Fragment>
      <GlobalStyles />
      {children}
    </React.Fragment>
  );
};

Page.propTypes = propTypes;
export default Page;
