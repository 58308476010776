import React from "react";
import * as R from "ramda";
import * as S from "./Images.styles.js";

import { arrayOf, number, shape, string } from "prop-types";
import GatsbyImage from "gatsby-image";

const propTypes = {
  images: arrayOf(
    // gatsby fixed image
    shape({
      aspectRatio: number,
      base64: string,
      height: number,
      originalName: string,
      src: string,
      srcSet: string,
      srcSetWebp: string,
      srcWebp: string,
      width: number,
    }),
  ),
};

const Images = (props) => {
  const { images } = props;

  return (
    <S.Container>
      {R.take(1, images).map((image, i) => (
        <GatsbyImage key={i} fixed={image} />
      ))}
    </S.Container>
  );
};

Images.propTypes = propTypes;
export default Images;
