import React from "react";
import { array, bool, node, string } from "prop-types";
import * as S from "./Project.styles.js";

import Images from "./Images.jsx";

const propTypes = {
  date: node,
  images: array, // type checked in Images.jsx
  html: string,
  isOdd: bool,
  technologies: node,
  title: node,
  url: string,
  slug: string,
};

const Project = (props) => {
  const { date, images, html, isOdd, title, technologies, url, slug } = props;

  return (
    <S.Container isOdd={isOdd} id={slug ? slug : null}>
      <S.Header>
        <h2>{title}</h2> <S.Date>{date}</S.Date>
        {url && (
          <a href={url} target="_blank" rel="noreferrer">
            {url}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-external-link"
            >
              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
              <polyline points="15 3 21 3 21 9"></polyline>
              <line x1="10" y1="14" x2="21" y2="3"></line>
            </svg>
          </a>
        )}
        {technologies && (
          <S.Technologies>
            <strong>Technologies used:</strong> <code>{technologies}</code>
          </S.Technologies>
        )}
      </S.Header>
      <S.Body>
        {images && images.length > 0 && (
          <S.Images>
            <Images images={images} />
          </S.Images>
        )}

        <div dangerouslySetInnerHTML={{ __html: html }} />
      </S.Body>
    </S.Container>
  );
};

Project.propTypes = propTypes;
export default Project;
